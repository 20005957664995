<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">问答列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">新建问题</el-button>
      </el-col>
      <el-col :span="24">
        <el-tabs value="reply">
            <el-tab-pane label="已回答列表" name="reply">
                <el-table :data="replyList" style="width: 100%">
                    <el-table-column label="题目" sortable>
                      <template slot-scope="scope">
                        <span>{{cutStr(scope.row.question)}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="likedNum" label="点赞数" sortable></el-table-column>
                    <el-table-column label="是否精选" sortable>
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isHandpick" @change="doHandpick(scope.row)" active-color="#13ce66"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="getDetail(scope.row)" size="mini">详情</el-button>
                            <el-button type="primary" @click="editLikeNum(scope.row)" size="mini">设置点赞数</el-button>
                            <el-button type="danger" @click="onDelete(scope.row)" size="mini">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="未回答列表" name="unReply">
                <el-table :data="unReplyList" style="width: 100%">
                    <el-table-column label="题目" sortable>
                      <template slot-scope="scope">
                        <span>{{cutStr(scope.row.question)}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="likedNum" label="点赞数" sortable></el-table-column>
                    <!-- <el-table-column label="是否精选" sortable>
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isHandpick" @change="doHandpick(scope.row)" active-color="#13ce66"></el-switch>
                        </template>
                    </el-table-column> -->
                    <el-table-column fixed="right" label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="putAnswer(scope.row)" size="mini">回答问题</el-button>
                            <el-button type="danger" @click="onDelete(scope.row)" size="mini">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <el-dialog title="点赞数" width="30%" :visible.sync="likeTypeVisible" >
        <el-form>
            <el-form-item>
                <el-input v-model="likeNum"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="likeTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addLikeNum">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog title="问答详情" width="30%" :visible.sync="detailTypeVisible" >
        <el-form>
            <el-form-item label="题目">
                <el-input v-model="form.question"></el-input>
            </el-form-item>
            <el-form-item label="答案">
                <el-input type="textarea" v-model="form.reply"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="detailTypeVisible = false">关 闭</el-button>
        </div>
    </el-dialog>
    <el-dialog title="回答问题" width="30%" :visible.sync="replyTypeVisible" >
        <el-form>
            <el-form-item label="题目">
                <el-input v-model="form.question"></el-input>
            </el-form-item>
            <el-form-item label="答案">
                <el-input type="textarea" v-model="form.reply" placeholder="请填写答案"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="replyTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addReply">确 定</el-button>
        </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      replyList: [],
      unReplyList: [],
      likeTypeVisible: false,
      likeNum: '',
      likeGuid: '',
      replyTypeVisible: false,
      form: {
        guid: '',
        question: '',
        reply: ''
      },
      detailTypeVisible: false
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getQAndAList()
  },
  methods: {
    // 获取已回答和未回答列表
    getQAndAList() {
        let self = this;
        self.$axios
            .get("/api/tree-hole/reply/list", {
            headers: { token: self.userInfo.token },
            })
            .then(function (response) {
            self.replyList = response.data.data;
            })
            .catch(function (error) {
            self.$message({
                message: "已回答列表获取失败："+error.response.data.message,
                type: "warning",
            });
            });
        self.$axios
            .get("/api/tree-hole/unReply/list", {
            headers: { token: self.userInfo.token },
            })
            .then(function (response) {
            self.unReplyList = response.data.data;
            })
            .catch(function (error) {
              self.$message({
                  message: "未回答列表获取失败："+error.response.data.message,
                  type: "warning",
              });
            });
    },
    // 新建问题
    createUser() {
      let self = this;
      self.$router.push({ name: "treeHoleQ&ADetail", query: { guid: null } });
    },
    // 删除
    onDelete(row) {
      let self = this;
      self
        .$confirm("确定删除 " + (row.question || "未命名") + "？")
        .then(() => {
          self.$axios
            .delete("/api/tree-hole/delete", {
              data: { guid: row.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getQAndAList()
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 修改点赞数
    editLikeNum(row) {
        let self = this
        self.likeNum = row.likedNum
        self.likeGuid = row.guid
        self.likeTypeVisible = true
    },
    // 确认修改点赞数
    addLikeNum() {
        let self = this
        let data = {
            guid: self.likeGuid,
            num: parseInt(self.likeNum)
        }
        self.$axios.put('/api/tree-hole/liked/num',data,{ headers: { token: self.userInfo.token }})
        .then(function(res) {
            self.$message({
                type: 'success',
                message: res.data.message
            })
            self.getQAndAList()
            self.likeTypeVisible = false
        })
    },
    // 是否精选
    doHandpick(row) {
        let self = this
        let data = {
            guid: row.guid
        }
        self.$axios.put('/api/tree-hole/handpick',data,{ headers: { token: self.userInfo.token }})
        .then(function(res) {
            self.$message({
                type: 'success',
                message: res.data.message
            })
        })
        .catch(function(err) {
            self.$message({
                  message: "设置失败："+err.response.data.message,
                  type: "warning",
              });
        })
    },
    // 点击回答问题
    putAnswer(row) {
      let self = this
      self.form.guid = row.guid
      self.form.question = row.question
      self.form.reply = row.reply
      self.replyTypeVisible = true
    },
    // 确认回答问题
    addReply() {
      let self = this
      let data = {
        guid: self.form.guid,
        reply: self.form.reply
      }
      self.$axios.put('/api/tree-hole/reply',data,{
        headers: { token: self.userInfo.token }
      })
      .then(function(res) {
        self.$message({
          message: res.data.message,
          type: "success",
        });
        self.getQAndAList()
        self.replyTypeVisible = false
      })
      .catch(function(err) {
          self.$message({
            message: "回答失败："+err.response.data.message,
            type: "warning",
          });
      })
    },
    // 查看问题详情
    getDetail(row) {
      let self = this
      self.form.guid = row.guid
      self.form.question = row.question
      self.form.reply = row.reply
      self.detailTypeVisible = true
    },
    // 截取字符串
    cutStr(str) {
      if(str.length > 9) {
        return str.slice(0,9)+'...'
      } else {
        return str
      }
    }
  }
};
</script>
